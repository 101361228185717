@import '../../dictionaries/themes/colors.module.scss';

$gutter: 8px;

.section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 820px;
  height: calc(100vh - 40px);
}

.description,
.link {
  font-size: $gutter * 2.5;
}

.link {
  color: $blue;
  font-weight: 700;
}

.title {
  font-size: $gutter * 2.5;
  font-weight: 700;
  margin-bottom: $gutter * 2;
}

.infoBoxContainer {
  display: flex;
  margin-bottom: $gutter * 10.5;
  justify-content: space-between;
}

.infoBox {
  width: 42%;
  .infoBoxDescription {
    margin-bottom: $gutter * 2.5;
  }
}

.subscribe {
  display: flex;
  justify-content: space-around;
  background: #f8f8f8;
  border-radius: 24px;
  text-align: left;
  padding: 24px 40px;
  align-items: center;
}

.subscribeSection,
.subscribeForm {
  display: flex;
  width: 45%;
  position: relative;
}

.subscribeSection {
  flex-direction: column;
}

@media (max-width: 640px) {
  .section {
    height: 100%;
  }
  .infoBoxContainer {
    flex-direction: column;
    padding: $gutter * 2;
  }

  .infoBox {
    width: 100%;
  }

  .infoBox:not(:last-child) {
    margin-bottom: $gutter * 8;
  }

  .subscribe {
    width: 90vw;
    flex-direction: column;
    margin-bottom: $gutter * 2;
    min-height: 240px;
  }

  .subscribeSection,
  .subscribeForm {
    display: flex;
    position: relative;
  }

  .subscribeSection {
    margin-bottom: $gutter * 4;
    width: 100%;
  }
}
