@import '../../../dictionaries/themes/colors.module.scss';

$transition: all 300ms ease-in-out;

.sideDescription {
  width: 35%;
  right: 0;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(43, 43, 43, 0.2);
  border-radius: 24px;
  padding: 32px 18px;
  transition: $transition;
  z-index: -1;
}

.sideDescriptionToolTip {
  position: absolute;
  display: flex;
  align-items: center;
  $position: 24px;
  right: $position;
  top: $position;
  .questionMarkIcon {
    background: $blue;
    color: #ffffff;
    $size: 40px;
    width: $size;
    height: $size;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    display: flex;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-left: 8px;
    z-index: 30;
    cursor: pointer;
  }
}

.questionMarkIconMobile {
  display: none !important;
}

.show {
  opacity: 1;
  z-index: 20;
}

.hide {
  opacity: 0;
}

@media (max-width: 1265px) {
  .sideDescriptionToolTip {
    top: 12px;
  }
  .toolTipTitle {
    display: none;
  }
}

@media (max-width: 1024px) {
  .sideDescription {
    width: 100%;
  }
  .questionMarkIcon {
    display: none !important;
  }
  .questionMarkIconMobile {
    display: flex !important;
  }
}
