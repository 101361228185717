@import './dictionaries/themes/colors.module.scss';

$gutter: 8px;
$border: $gutter/2 solid $blue;
$transition: all 300ms ease-in-out;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $black;
}

body {
  font-family: 'Lora', serif !important;
  font-weight: 400;
  p,
  li,
  input,
  a {
    font-family: 'Source Sans Pro', sans-serif !important;
    text-decoration: none;
    margin: 0;
  }
  font-size: 16px;
}

// Basic section page

:global(.section) {
  padding: 100px 40px 0 40px;
}

:global(.wrapper) {
  display: flex;
  border: $border;
  height: calc(100vh - 140px);
  position: relative;
}

:global(.title) {
  font-weight: 700;
  font-size: 24px;
  font-size: $gutter * 3;
  padding: $gutter * 2;
}

:global(.description) {
  padding: 16px;
  line-height: $gutter * 3;
  p:not(:last-child) {
    margin-bottom: $gutter * 2;
  }

  ul {
    padding-left: $gutter * 2;
    list-style: none;
    li {
      margin-bottom: $gutter * 2;
    }
    li::before {
      content: '\2022';
      color: $blue;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

:global(.mainSection) {
  width: 100%;
  position: relative;
  text-align: center;
}

:global(.bold) {
  font-weight: 700;
}

// Button btn

:global(.btn) {
  padding: 12px 16px !important;
  max-width: 100% !important;
  border: 2px solid transparent !important;
  background: transparent !important;
  cursor: pointer !important;
  transition: $transition !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

:global(.btn-primary) {
  background: $blue !important;
}

:global(.btn-secondary) {
  border: none !important;
  color: $blue !important;
  font-weight: 700 !important;
}

:global(.lineDivider) {
  margin-bottom: $gutter * 4;
  border: 1px solid #000000;
  width: 80px;
}

:global(.headerTitle) {
  font-size: $gutter * 4;
  padding: $gutter * 5 0 $gutter * 2 0;
  font-weight: 700;
}

@media (max-width: 1265px) {
  :global(.wrapper) {
    flex-direction: column;
    height: initial;
    border: none;
  }

  :global(.section) {
    padding: 100px 0 !important;
  }

  :global(.mainSection) {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
