$gutter: 8px;

.container {
  background: transparent;
  border: none;
}

.modalBackDrop {
  background: rgba(43, 43, 43, 0.9) !important;
  opacity: 1 !important;
}

.closeBtn {
  color: #ffffff;
  display: flex;
  align-self: flex-end;
  position: absolute;
  top: -$gutter * 6;
  font-size: $gutter * 3;
  $size: $gutter * 2;
  width: $gutter * 6;
}

.body {
  text-align: center;
}

.title {
  display: flex;
  justify-content: center;
}

.header,
.footer {
  border: none;
  display: flex;
  justify-content: center;
}

.body {
  color: #ffffff;
  font-weight: 700;
  font-family: 'Source Sans Pro';
}

.thankYouBody {
  font-size: 48px;
}

.goHomeBtn {
  width: 180px;
  border-radius: 50px;
}

@media (max-width: 640px) {
  .thankYouBody {
    font-size: 24px;
  }
}
