@import '../../../dictionaries/themes/colors.module.scss';

$gutter: 8px;

.backBtn {
  position: absolute;
  display: none;
  z-index: 10;
  left: 20px;
  top: 20px;
  width: 120px;
}

.anatomy {
  display: flex;
  height: 100%;
}

.backgroundText {
  position: absolute;
  top: 35%;
  left: 50%;
  text-transform: uppercase;
  transform: translate(-50%);
  font-size: 11rem;
  z-index: -10;
  font-weight: 700;
  padding: 0 $gutter * 4;
}

.instructions {
  margin-top: -$gutter * 10;
  font-weight: 700;
  letter-spacing: 0.02em;
  display: flex;
  flex-direction: column;
}

.approvedInstructions {
  font-size: 24px;
}

.chevron {
  color: $blue;
  margin-right: $gutter/2;
}

@media (max-width: 1265px) {
  .anatomy {
    width: 80%;
  }

  .instructions {
    margin-top: 0;
  }
}

@media (max-width: 420px) {
  .backgroundText {
    font-size: 3rem;
    top: 0;
  }
  .instructions {
    font-size: 14px;
  }
  .approvedInstructions {
    font-size: 16px;
  }
}
