@import '../../dictionaries/themes/colors.module.scss';

$gutter: 8px;

.section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: $gutter * 64.5;
}

.title {
  color: $blue;
  font-weight: 700;
}

.whatsComingList {
  margin-top: $gutter * 7;
}

.whatsComingItem {
}

.emoStarDivider {
  display: flex;
  justify-content: center;
  margin: $gutter * 2 0;
}
