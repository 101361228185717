.wrapper {
  width: 100%;
  height: calc(100vh - 480px);
  min-height: 300px;
  position: relative;
}

.media {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}

@media (max-width: 640px) {
  .wrapper {
    height: 300px;
  }
}
