@import '../../dictionaries/themes/colors.module.scss';

$gutter: 8px;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $gutter * 4 $gutter * 6;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  z-index: 40;
}

.hamburger {
  $size: $gutter * 4;
  height: $size;
  width: $size;
  display: none;
}

.logo {
  max-height: $gutter * 4;
}

.logoIcon {
  display: none;
}

.socialIcons {
  display: inline-flex;
  .icon {
    $size: 24px;
    height: $size;
    width: $size;
  }
}

.links {
  a {
    text-decoration: none;
    font-weight: 700;
    color: $black;
  }
  a:not(:last-child) {
    margin-right: $gutter * 3;
  }
}

@media (max-width: 900px) {
  .container {
    padding: $gutter * 2;
    width: 100vw;
  }
  .hamburger {
    display: block;
  }
  .logoWithText {
    display: none;
  }
  .logoIcon {
    display: flex;
  }
  .links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $gutter * 7;
    right: $gutter * 2;
    box-shadow: 0px 8px 16px rgba(43, 43, 43, 0.2);
    padding: $gutter * 2 $gutter * 4;
    background: #ffffff;
    border-radius: $gutter;
    transition: all 300ms ease-in-out;
    z-index: 30;
    a:not(:last-child) {
      margin-bottom: $gutter * 3;
    }
  }
  .show {
    opacity: 1;
    z-index: 20;
  }

  .hide {
    opacity: 0;
    right: $gutter * -20;
  }
}
