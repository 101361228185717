@import '../../dictionaries/themes/colors.module.scss';

$gutter: 8px;

$border: $gutter/2 solid $blue;

.emoBullet {
  display: flex;
  margin-left: -7px;
  align-items: baseline;
  .starEmo {
    margin-right: $gutter / 2;
  }
}
