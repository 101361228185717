@import '../../dictionaries/themes/colors.module.scss';

$gutter: 8px;

.header {
  font-family: Lora;
  font-weight: 700;
  font-size: 36px;
  margin: $gutter * 3 0 $gutter * 9;
  color: $blue;
}

.excercisesWrapper {
  margin-top: $gutter * 4;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.excercisesContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: $gutter;
}

.thumbnailContainer {
  transition: 0ms;
  padding: 8px;
  border: 4px solid transparent;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.thumbnailContainer:not(:last-child) {
  margin-right: $gutter * 2;
}

.thumbnail {
  width: $gutter * 10;
  height: $gutter * 10;
}

.activeThumbnail {
  border: 4px solid $blue;
}

.backBtn {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 20px;
  width: 120px;
  text-decoration: none;
}

.chevron {
  color: $blue;
  margin-right: $gutter/2;
}

.handTechniquesContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: $gutter;
}

.handTechnique {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: $gutter * 2;
}

@media (max-width: 1024px) {
  .handTechniquesContainer {
    justify-content: flex-start;
  }
}

@media (max-width: 640px) {
  .backBtn {
    top: -36px;
    left: 8px;
  }
  .header {
    margin: $gutter 0;
  }
  .excercisesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
